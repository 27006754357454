// import CompaniesCarousel from './companiesCarousel'
import { useContext } from 'react'
import { LanguageContext } from '../hook/context'
import dynamic from 'next/dynamic'

const CompaniesCarousel = dynamic(() => import('./companiesCarousel'))

const Companies = () => {
  const { t } = useContext(LanguageContext)
  return (
    <div className='flex flex-col  items-center justify-center text-center'>
      <h2 className='title mt-10 leading-tight'>{t.companies.title}</h2>
      {t.companies.subtitle}
      <CompaniesCarousel />
    </div>
  )
}

export default Companies
